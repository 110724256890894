<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
        <CTab active>
          <template slot="title">
            <CIcon name="cil-task" />&nbsp;Filtre
          </template>
          <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
              <CCol col="3" sm="2">
                <CButton
                  type="submit"
                  size="sm"
                  color="primary"
                  @click="filter"
                >
                  <CIcon name="cil-check-circle" />
                  Filtrele
                </CButton>
              </CCol>
              <CCol col="3" sm="2">
                <CButton
                  type="reset"
                  size="sm"
                  color="danger"
                  @click="clearFilters"
                >
                  <CIcon name="cil-ban" />
                  Temizle
                </CButton>
              </CCol>
            </CRow>
            <hr />
            <CRow style="margin-top: 20px">
              <CCol>
                <CSelect
                  label="E-mailden Onay Verildi mi"
                  horizontal
                  :options="comboDataList"
                  :value.sync="data.emailValidFlag"
                >
                </CSelect>
              </CCol>
            </CRow>
          </CForm>
        </CTab>
    </CTabs>
</template>

<script>
   export default{
       name: "CallEventFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
               comboDataList: [
               {label: 'Hepsi', value: ''},
                {label: 'Evet', value: true},
                {label: 'Hayır', value: false},
              ]
           }
       },
       methods:{
            clearFilters: function(){
                this.data = {}
                this.filter(this.data)
            }
       }
   }
</script>